import axios from '@/plugins/axios';

export default {
  getOrganisationNotes(organisationId, config = {}) {
    return axios.get(`/v1/master/organisations/${organisationId}/notes`, config);
  },
  createOrganisationNote(organisationId, data, config = {}) {
    return axios.post(`/v1/master/organisations/${organisationId}/notes`, data, config);
  },
  deleteOrganisationNote(organisationId, noteId, config = {}) {
    return axios.delete(`/v1/master/organisations/${organisationId}/notes/${noteId}`, config);
  },
};
