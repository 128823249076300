import axios from '@/plugins/axios';

export default {
  getOrganisationUsers(organisationId, config = {}) {
    return axios.get(`/v1/master/organisations/${organisationId}/users`, config);
  },
  createOrganisationUser(organisationId, data, config = {}) {
    return axios.post(`/v1/master/organisations/${organisationId}/users`, data, config);
  },
  getOrganisationUser(organisationId, userId, config = {}) {
    return axios.get(`/v1/master/organisations/${organisationId}/users/${userId}`, config);
  },
  updateOrganisationUser(organisationId, userId, data, config = {}) {
    return axios.put(`/v1/master/organisations/${organisationId}/users/${userId}`, data, config);
  },
  loginAsOrganisationUser(organisationId, userId, data = {}, config = {}) {
    return axios.post(`/v1/master/organisations/${organisationId}/users/${userId}/login`, data, config);
  },
  logoutOrganisationUser(organisationId, userId, config = {}) {
    return axios.delete(`/v1/master/organisations/${organisationId}/users/${userId}/logout`, config);
  },
  deleteOrganisationUser(organisationId, userId, config = {}) {
    return axios.delete(`/v1/master/organisations/${organisationId}/users/${userId}`, config);
  },
};
