<template>
  <div>
    <page-heading>
      <template v-slot:heading>
        <div class="flex" :class="{
          'invisible': !modelOriginal.name,
        }">
          <h1 class="text-2xl font-semibold uppercase">{{ modelOriginal.name }}</h1>
          <badge class="ml-2 text-sm"
            :label="$t(`organisation_status.${modelOriginal.status}`)"
            :theme="getStatusTheme(modelOriginal.status)"
          />
        </div>
      </template>

      <template v-slot:actions>
        <app-button :label="$t('create')" v-show="activeTab === 'users'" @click="openCreateUserModal()"/>
      </template>

      <template v-slot:tabs>
        <tabs
          :tabs="tabs"
          :activeTab="activeTab"
          @switchTab="switchTab"
        />
      </template>
    </page-heading>

    <loading-wrapper>
      <template v-if="activeTab === 'basic'">
        <form-open class="max-w-2xl" @submit="update">
          <debug>{{ model }}</debug>
          <div class="grid grid-cols-2 gap-4">
            <form-text input-id="name" :form-id="formIds.editOrganisation" form-error-id="name" :label="$t('model.organisation.name')" v-model="model.name"/>
            <form-select input-id="status" :form-id="formIds.editOrganisation" form-error-id="status" :label="$t('status')" :options="data.statuses" v-model="model.status" :allow-empty="true"/>
          </div>
          <div class="flex justify-end mt-4">
            <app-button :label="$t('update')"/>
          </div>
        </form-open>
      </template>

      <template v-if="activeTab === 'users'">
        <app-table :items="items">
          <template
            v-slot:headings
          >
            <app-th :heading="$t('model.user.name')"/>
            <app-th :heading="$t('model.user.status')"/>
            <app-th :heading="$t('model.user.last_login')"/>
            <app-th :heading="$t('model.user.sessions')"/>
            <app-th></app-th>
          </template>

          <template
            v-slot:row="{ item }"
          >
            <app-td>
              <a href="#" @click.prevent="openEditUserModal(item.id)">
                <div>{{ item.name }}</div>
                <div class="text-gray-400">{{ item.username }}</div>
              </a>
            </app-td>
            <app-td>
              <badge :label="$t(`user_status.${item.status}`)" :theme="getUserStatusTheme(item.status)"/>
            </app-td>
            <app-td :data="item.last_login_diff ? item.last_login_diff : $t('never')"/>
            <app-td>
              <div class="flex space-x-2">
                <div class="flex gap-1">
                  <app-svg class="w-4" svg="desktop-computer"/>
                  <span class="text-xs">{{ item.sessions_count }}</span>
                </div>
                <div class="flex gap-1">
                  <app-svg class="w-4" svg="device-mobile"/>
                  <span class="text-xs">{{ item.tokens_count }}</span>
                </div>
              </div>
            </app-td>
            <app-td>
              <app-options>
                <div>
                  <app-option-edit @click="openEditUserModal(item.id)"/>
                  <app-option
                    :label="$t('log_in_as_this_user')"
                    svg="user-circle"
                    @click="logInAsUser(item.id)"
                  />
                  <app-option
                    :class="{
                    'disabled': (item.sessions_count + item.tokens_count) === 0,
                  }"
                    :label="$t('log_out_user')"
                    @click="logoutUser(item.id)"
                    svg="logout"
                  />
                </div>
                <app-option-delete @click="openDeleteUserModal(item.id)"/>
              </app-options>
            </app-td>
          </template>
        </app-table>
        <pagination
          :meta="meta"
          :page="query.page"
          v-if="meta"
          @pageUpdated="updatePage"
        />
      </template>

      <template v-if="activeTab === 'notes'">
        <notes :items="notes.items" :meta="notes.meta" :form-id="formIds.createOrganisationNote" @submit="createNote" @delete="deleteNote"/>
      </template>
    </loading-wrapper>

    <slideover :wide="true" :active="modals.createEditUser" @close="closeModal('createEditUser')" @submit="createEditUser"
      :title="user.id ? $t('edit') : $t('create')"
    >
      <debug>{{ user.model }}</debug>

      <div class="grid grid-cols-2 gap-4">
        <form-text class="col-span-full" :form-id="formIds.createEditOrganisationUser" input-id="username" form-error-id="username" :label="$t('model.user.username')" v-model="user.model.username" v-if="user.id" :canCopy="true"/>
        <form-text :form-id="formIds.createEditOrganisationUser" input-id="first_name" form-error-id="first_name" :label="$t('model.user.first_name')" v-model="user.model.first_name"/>
        <form-text :form-id="formIds.createEditOrganisationUser" input-id="last_name" form-error-id="last_name" :label="$t('model.user.last_name')" v-model="user.model.last_name"/>
        <form-text type="email" icon="inbox"
          :form-id="formIds.createEditOrganisationUser"
          input-id="email"
          form-error-id="email"
          :label="$t('model.user.email')"
          v-model="user.model.email"
          :required="false"
          :help="user.id ? '' : $t('plain_text_password_email')"
        />
        <form-select
          :form-id="formIds.createEditOrganisationUser"
          input-id="status"
          form-error-id="status"
          :label="$t('model.user.status')"
          :options="data.userStatuses"
          v-model="user.model.status"
          v-if="user.id"
        />
      </div>

      <a href="#" class="flex items-center text-gray-600" v-show="user.id" @click.prevent="togglePasswordFields">
        <app-svg class="h-5 w-5" :svg="!showPasswordFields ? 'lock-closed' : 'lock-open'"/>
        <span class="ml-2 text-sm">{{ $t('update_password') }}</span>
      </a>

      <div class="col-span-full grid grid-cols-2 gap-2">
        <form-text :disabled="!showPasswordFields" :form-id="formIds.createEditOrganisationUser" input-id="password" form-error-id="password" type="password" :label="$t('model.user.password')" v-model="user.model.password" :canCopy="true"/>
        <form-text :disabled="!showPasswordFields" :form-id="formIds.createEditOrganisationUser" input-id="password_confirmation" form-error-id="password_confirmation" type="password" :label="$t('model.user.password_confirmation')" v-model="user.model.password_confirmation"/>
      </div>

      <form-select
        :form-id="formIds.createEditOrganisationUser"
        :label="$t('model.user.permissions')"
        :multiple="true"
        :options="data.permissions"
        class="col-span-full"
        form-error-id="permissions"
        input-id="permissions"
        v-model="user.model.permissions"
        v-if="user.id"
      />

      <template v-slot:buttons>
        <app-button
          :label="$t(user.id ? 'update' : 'create')"
          :disabled="loading"
          :loading="loading"
        />
      </template>
    </slideover>

    <modal-delete :active="modals.deleteUser" @close="closeModal('deleteUser')" @delete="deleteUserModel(user.id)"
      :title="$t('delete')"
      :text="$t('delete_thing_text', { thing: user.model.username })"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import ApiAuthService from '@/services/api/auth';
import ApiMasterOrganisationNoteService from '@/services/api/master/organisation_note';
import ApiMasterOrganisationService from '@/services/api/master/organisation';
import ApiMasterOrganisationUserService from '@/services/api/master/organisation_user';
import ApiOrganisationStatusService from '@/services/api/organisation_status';
import ApiUserStatusService from '@/services/api/user_status';
import Common from '@/mixins/common';
import Notes from '@/components/Notes.vue';
import store from '@/store';
import { SET_USER } from '@/store/mutation-types';
import ApiPermissionService from '@/services/api/permission';

export default {
  components: {
    Notes,
  },
  data() {
    return {
      activeTab: 'basic',
      data: {
        organisationName: null,
        statuses: [],
        userStatuses: [],
        userStatusIds: [],
      },
      formIds: {
        editOrganisation: 'editOrganisation',
        createEditOrganisationUser: 'createEditOrganisationUser',
        createOrganisationNote: 'createOrganisationNote',
      },
      items: [],
      meta: {},
      model: {},
      modelOriginal: {},
      modals: {
        createEditUser: false,
        deleteUser: false,
      },
      notes: {
        model: {},
        items: [],
        meta: {},
      },
      query: {
        page: 1,
        sort: [
          'last_name',
        ],
      },
      showNotesPrompt: false,
      showPasswordFields: false,
      tabs: [
        {
          id: 'basic',
          icon: 'cube',
          label: this.$t('basic'),
        },
        {
          id: 'users',
          icon: 'user-group',
          label: this.$t('top_level_users'),
        },
        {
          id: 'notes',
          icon: 'chat',
          label: this.$t('notes'),
        },
      ],
      user: {
        id: null,
        model: {},
      },
    };
  },
  metaInfo() {
    return {
      title: this.$t('edit_thing', { thing: this.$t('organisation'), name: this.model.name }),
    };
  },
  methods: {
    createNote(model) {
      ApiMasterOrganisationNoteService.createOrganisationNote(this.$route.params.organisationId, model, {
        formId: this.formIds.createOrganisationNote,
        showMessage: true,
      }).then(() => {
        this.getNotes();
      });
    },
    deleteUserModel(modelId) {
      ApiMasterOrganisationUserService.deleteOrganisationUser(this.$route.params.organisationId, modelId).then(() => {
        this.closeModal('deleteUser');
        this.getAndSetUsers();
      }).catch(() => {});
    },
    getListings() {
      this.getAndSetUsers();
    },
    userResourceToModel(resource) {
      return _(resource)
        .pick([
          'email',
          'first_name',
          'last_name',
          'permissions',
          'username',
          'status',
        ])
        .mapValues((value) => (_.isNull(value) ? '' : value))
        .value();
    },
    openCreateUserModal() {
      this.clearFormErrors(this.formIds.createEditOrganisationUser);
      this.showPasswordFields = false;

      this.user.id = null;
      this.user.model = {};
      this.openModal('createEditUser');
    },
    openDeleteUserModal(modelId) {
      ApiMasterOrganisationUserService.getOrganisationUser(this.$route.params.organisationId, modelId).then((response) => {
        const { data } = response.data;

        this.user.id = data.id;
        this.user.model = this.userResourceToModel(data);
        this.openModal('deleteUser');
      }).catch(() => {});
    },
    openEditUserModal(userId) {
      ApiMasterOrganisationUserService.getOrganisationUser(this.$route.params.organisationId, userId, {
        params: {
          include: [
            'permissions',
          ],
        },
      }).then((response) => {
        const { data } = response.data;

        this.user.id = data.id;
        this.user.model = this.userResourceToModel(data);
        this.openModal('createEditUser');
      });
    },
    createEditUser() {
      if (this.user.id) {
        ApiMasterOrganisationUserService.updateOrganisationUser(this.$route.params.organisationId, this.user.id, this.user.model, {
          formId: this.formIds.createEditOrganisationUser,
          showMessage: true,
        }).then(() => {
          this.getAndSetUsers();
          this.closeModals();
        }).catch(() => {});
      } else {
        ApiMasterOrganisationUserService.createOrganisationUser(this.$route.params.organisationId, this.user.model, {
          formId: this.formIds.createEditOrganisationUser,
          showMessage: true,
        }).then((response) => {
          const { data } = response.data;

          this.user.id = data.id;
          this.user.model = this.userResourceToModel(data);

          this.getAndSetUsers();
          this.closeModals();
        }).catch(() => {});
      }
    },
    deleteNote(noteId) {
      ApiMasterOrganisationNoteService.deleteOrganisationNote(this.$route.params.organisationId, noteId).then(() => {
        this.getNotes();
      });
    },
    getNotes() {
      ApiMasterOrganisationNoteService.getOrganisationNotes(this.$route.params.organisationId, {
        params: { // TODO
          /*
          sort: [
            'created_at',
          ],
          */
        },
      }).then((response) => {
        const { data, meta } = response.data;

        this.notes = {
          items: data,
          meta,
        };
      });
    },
    getStatusTheme(statusId) {
      return {
        1: 'success',
        8: 'primary',
        9: 'error',
        10: 'warning',
      }[statusId];
    },
    getAndSetUsers() {
      ApiMasterOrganisationUserService.getOrganisationUsers(this.$route.params.organisationId, {
        params: {
          ...this.query,
          include: [
            'lastLogin',
            'sessionsCount',
            'tokensCount',
          ],
        },
      }).then((response) => {
        const { data, meta } = response.data;

        this.items = data;
        this.meta = meta;
      }).catch(() => {});
    },
    getOrganisation() {
      ApiMasterOrganisationService.getOrganisation(this.$route.params.organisationId).then((response) => {
        const { data } = response.data;

        this.model = _.pick(data, [
          'name',
          'status',
        ]);
        this.modelOriginal = {
          ...this.model,
        };
      }).catch(() => {});
    },
    getUserStatusTheme(userStatusId) {
      return {
        1: 'success',
        9: 'primary',
        10: 'error',
      }[userStatusId];
    },
    logInAsUser(userId) {
      ApiMasterOrganisationUserService.loginAsOrganisationUser(this.$route.params.organisationId, userId).then(() => {
        ApiAuthService.user({
          params: {
            include: [
              'organisation',
              'permissions',
            ],
          },
        }).then((response) => {
          store.commit(`auth/${SET_USER}`, response.data.data);
          this.$router.push({
            name: 'dashboard',
          });
        });
      });
    },
    logoutUser(userId) {
      ApiMasterOrganisationUserService.logoutOrganisationUser(this.$route.params.organisationId, userId)
        .then(() => {
          this.getAndSetUsers();
        })
        .catch(() => {});
    },
    switchTab(tabId) {
      this.clearFormErrors(this.formIds.createOrganisationNote);

      this.activeTab = tabId;
    },
    togglePasswordFields() {
      this.showPasswordFields = !this.showPasswordFields;

      if (!this.showPasswordFields) {
        this.user.model.password = '';
        this.user.model.password_confirmation = '';
      }
    },
    update() {
      ApiMasterOrganisationService
        .updateOrganisation(this.$route.params.organisationId, this.model, {
          formId: this.formIds.editOrganisation,
          showMessage: true,
        })
        .then(() => {
          if (this.model.status !== this.modelOriginal.status) {
            this.switchTab('notes');
            this.$store.dispatch('messages/addMessage', {
              type: 'info',
              message: this.$t('organisation_update_note_prompt'),
            });
          }

          this.getOrganisation();
        })
        .catch(() => {});
    },
  },
  mixins: [
    Common,
  ],
  mounted() {
    ApiOrganisationStatusService.getOrganisationStatuses().then((response) => {
      const { data } = response.data;

      this.data.statuses = this.toSelectOptions(data, 'status', 'id', (label) => `organisation_status.${label}`);
    }).catch(() => {});

    this.getOrganisation();
    this.getAndSetUsers();

    ApiUserStatusService.getStatuses().then((response) => {
      const { data } = response.data;

      this.data.userStatuses = this.toSelectOptions(data, 'status', 'id', (label) => `user_status.${label}`);
    }).catch(() => {});

    ApiPermissionService.getOrganisationPermissions()
      .then((response) => {
        const { data } = response.data;

        this.data.permissions = this.toSelectOptions(data, 'name', 'id', (label, value) => `permissions.${value}.label`);
      })
      .catch(() => {});

    this.getNotes();
  },
};
</script>
